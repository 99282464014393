/* eslint-disable */
import './App.css';
import { React, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
let dataArr = [];
let temp = '';
let isPause = false;
let newarray = [];

function App() {

  let [a, setA] = useState('');
  let [b, setB] = useState('');
  let [name, setName] = useState('');
  let [group, setGroup] = useState('');
  const [dataArrState, setDataArrState] = useState([]);
  const [button, setButton] = useState('입력하기');
  const [qnatimeA, setQnatimeA] = useState(2);
  const [qnatimeB, setQnatimeB] = useState(2);
  const [pause, setPause] = useState('일시정지');
  let [color, setColor] = useState('red');
  const [play, setPlay] = useState('재생');
  const [random, setRandom] = useState('랜덤');



  const [expandedSubject, setExpandedSubject] = useState(null);

  const handleSubjectClick = (subjectName) => {
    if (expandedSubject === subjectName) {
      setExpandedSubject(null);
    } else {
      setExpandedSubject(subjectName);
      // document.querySelector('.group').classList.add('off')
    }
  };

  function createNewArrayWithIndices(array) {
    return array.map((_, index) => index);
  }

  const [highlighted, setHighlighted] = useState(false);

  const handleHighlightToggle = () => {
    console.log(highlighted)
    setHighlighted(!highlighted);
  };

  useEffect(() => {

    if (localStorage.getItem('data') !== null) {
      dataArr = JSON.parse(localStorage.getItem('data'));
      setDataArrState(dataArr);
    }
    speechSynthesis.resume();
    speechSynthesis.cancel();

    console.log('useEffect')
    return () => {
      console.log(localStorage.getItem('data'));
    }
  }, [])


  const speakText = (text, textArray, index) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);

    utterance.onend = (event) => {
      console.log('끝남')
      setTimeout(() => {
        console.log('settimeout 후 시작')
        speakSequentially(textArray, index + 1);
      }, qnatimeA * 1000);
    }

    synth.speak(utterance);


    if (index % 2 == 0) {
      console.log('짝수')
      if (speechSynthesis.paused == false) {
        setColor(textArray[index])
      }
    }

    else {
      console.log('홀수')
      if (speechSynthesis.paused == false) {
        setColor(textArray[index - 1])
      }
    }



  };

  const speakQnASequentially = (qnaList) => {
    isPause = false;
    let textArray = [];
    qnaList.forEach(qna => {
      textArray.push(qna.question);
      textArray.push(qna.answer);
    });
    speakSequentially(textArray, 0);
  };

  const speakSequentially = (textArray, index) => {

    if (index < textArray.length && isPause == false) {
      console.log(newarray)

      if (index % 2 == 0) {
        speakText(textArray[index], textArray, index);
        // setColor(textArray[index])
      }

      else {
        speakText(textArray[index], textArray, index);
        // setColor(textArray[index-1])
      }

    }

    // length는 넘어갔는데 ispause는 false가 아닐때
    else if (index >= textArray.length && isPause == false) {
      console.log(textArray)
      speakSequentially(textArray, 0)
    }

    else if (isPause == true) {
      console.log(isPause)
      speechSynthesis.cancel();
    }

  };

  const randomize = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const push = () => {
    if (group == '') {
      alert('그룹이 설정되지 않았습니다.')
    }

    else {
      dataArr.push({ name: group, QnA: [] })
      setGroup('')
      setDataArrState(dataArr);
      localStorage.setItem('data', JSON.stringify(dataArr));
      console.log(dataArrState)
    }
  }

  const modalOnOff = (e, toggle) => {
    document.querySelector(`.${e}`).classList.toggle('on-off')
  }


  return (
    <div className='MyContainer'>


      {/* 처음에 그룹추가하는 화면 */}
      <div className={`group ${highlighted ? 'highlighted' : ''}`}>

        <input
          placeholder='그룹추가'
          value={group}
          onChange={(e) => { setGroup(e.target.value) }}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              push();
            }
          }}
          className='groupInput'
        ></input>

        <Button
          variant='light'
          className='abc'
          onClick={() => {
            // 이 버튼을 누르면 그룹을 추가합니다. ( { name: "입력값", QnA: [ ] } )
            push();
          }}>+</Button>

        <Button
          variant='light'
          onClick={() => { modalOnOff('modalSetting') }}>설정
        </Button>

      </div>



      <div className='c'>
        {dataArrState.map((subject, index) => (
          <div key={subject.name}>

            <div className={`subject ${highlighted ? 'highlighted' : ''}`}>
              <Button
                variant="primary"
                className={`link ${highlighted ? 'highlighted' : ''}`}

                onClick={() => {
                  document.querySelector('.modalSetting').classList.remove('on-off')
                  handleSubjectClick(subject.name)
                  handleHighlightToggle()
                  isPause = true;
                  if (pause == '재개') {
                    setPause('일시정지')
                  }
                  newarray = createNewArrayWithIndices(subject.QnA)
                  console.log(newarray)

                }}
              >
                {subject.name}
              </Button>
              <Button
                variant='secondary'
                className={`button2 ${highlighted ? 'highlighted' : ''}`}
                onClick={() => {
                  // 이 버튼을 누르면 object가 삭제됩니다.
                  var result = window.confirm('삭제 하시겠습니까?');

                  if (result) {
                    dataArr.splice(index, index + 1);
                    let rrrrr = [...dataArr];
                    setGroup('')
                    setDataArrState(rrrrr);
                    localStorage.setItem('data', JSON.stringify(dataArr));
                  }
                }}
              >x</Button>
            </div>


            {expandedSubject === subject.name && (
              <div>
                <div>
                  <h1 className='main'>{subject.name}</h1>
                  <Button
                    variant='light'
                    className='back'
                    onClick={() => {
                      // 뒤로 가기 버튼을 누르면 기존의 그룹 화면을 보여주고 나머지를 숨깁니다.
                      document.querySelector('.MyModal').classList.remove('on-off');
                      handleHighlightToggle()
                      handleSubjectClick()
                      isPause = true;
                      speechSynthesis.resume()
                      speechSynthesis.cancel()
                      setColor('red')
                    }}>뒤로 가기</Button>
                </div>

                <div>
                  <div className='play'>
                    <Button
                      variant='light'
                      onClick={() => {
                        document.querySelector('.MyModal').classList.toggle('on-off');
                        setName(subject.name)
                        console.log(123)
                      }}>+</Button>

                    <Button
                      variant='info'
                      className='b1'
                      onClick={() => {

                        if (play == '재생') {
                          // 랜덤 버튼을 none으로 만들기
                          document.querySelector('.b2').classList.add('highlighted');
                          setPlay('중지')
                          speechSynthesis.resume();
                          speechSynthesis.cancel();
                          if (pause == '재개') {
                            setPause('일시정지')
                          }
                          speakQnASequentially(subject.QnA)
                          console.log(subject.QnA)
                        }

                        else {
                          // 랜덤 버튼을 visible로 만들기
                          document.querySelector('.b2').classList.remove('highlighted');
                          isPause = true;
                          speechSynthesis.resume();
                          speechSynthesis.cancel();
                          setColor('red')
                          setPlay('재생')
                          if (pause == '재개') {
                            setPause('일시정지')
                          }
                        }

                      }}>{play}</Button>

                    <Button
                      variant='warning'
                      className='b2'
                      onClick={() => {

                        if (random == '랜덤') {
                          document.querySelector('.b1').classList.add('highlighted');
                          setRandom('중지')
                          speechSynthesis.resume();
                          speechSynthesis.cancel();
                          let aaa = [...subject.QnA]
                          let bbb = randomize(aaa)
                          console.log(bbb)
                          speakQnASequentially(bbb)
                        }

                        else {
                          document.querySelector('.b1').classList.remove('highlighted');
                          setRandom('랜덤')
                          isPause = true;
                          speechSynthesis.resume();
                          speechSynthesis.cancel();
                          setColor('red')
                          if (pause == '재개') {
                            setPause('일시정지')
                          }
                        }

                      }
                      }>{random}</Button>
                  </div>

                  <div className='pause'>
                    <Button
                      variant='secondary'
                      onClick={() => {

                        if (pause == '일시정지') {
                          speechSynthesis.pause();
                          setPause('재개');
                          console.log(speechSynthesis.paused)
                          // setColor(index/2)
                        }

                        else {
                          speechSynthesis.resume();
                          setPause('일시정지');

                        }

                      }}>{pause}</Button>

                    {/* <button onClick={()=>{console.log(speechSynthesis.paused)}}>test</button> */}

                    {/* 중지 버튼 */}
                    {/* <button onClick={() => {
                    isPause = true
                    console.log('stopping')
                  }}>중지</button> */}
                  </div>


                </div>
                <div>
                  {subject.QnA.map((qna, index) => (

                    <div key={index}
                      // className='content' 
                      style={qna.question == color ? { backgroundColor: 'darkgray', width: '100%', height: '100%', textAlign: 'center', paddingTop: '3%', borderBottom: '1px' } : { backgroundColor: 'white', width: '100%', height: '100%', textAlign: 'center', paddingTop: '3%', borderBottom: '1px solid black' }}
                      onClick={() => {
                        speechSynthesis.resume();
                        speechSynthesis.cancel();
                        newarray = [];

                        // console.log(qna)
                        console.log(newarray)
                        let eeeeee = [...newarray]
                        let ffffff = eeeeee.splice(0, index)
                        eeeeee.push(...ffffff)
                        console.log(eeeeee)
                        newarray = eeeeee

                        let copy = [...subject.QnA]
                        let arr1 = copy.splice(0, index)
                        copy.push(...arr1)
                        console.log(copy)
                        speakQnASequentially(copy)

                        // 전체 인덱스를 푸쉬해 
                        // 인덱스를 만져주고
                        // 얘를 돌리기 cArray[0] = index
                        // 근데 이렇게하면 처음에는 빈 배열이기 때문에 에러가 뜰수 있음 일반재생할때
                      }}>

                      <p style={qna.question == color ? { color: 'blue' } : { color: 'black' }}>질문: {qna.question}</p>
                      <p style={qna.question == color ? { color: 'blue' } : { color: 'black' }}>답변: {qna.answer}</p>
                      {/* add classname ? 
                      인덱스에 해당하는 값의 클래스네임 */}

                      <div
                        className='mm'
                        onClick={(e) => {
                          const checkbox = document.querySelector('.content');
                          checkbox.addEventListener('click', event.preventDefault(), false);
                        }}
                      >

                        <button onClick={() => {
                          // 이 버튼을 누르면 버튼 이름이 수정하기로 바뀌며, 
                          // 그룹, 질문, 답이 설정됩니다.
                          // 변경하면 해당 내용이 덮어씌워집니다.
                          setButton('수정하기');
                          setName(subject.name);
                          setA(qna.question);
                          setB(qna.answer);
                          subject.QnA.splice();
                          document.querySelector('.MyModal').classList.toggle('on-off')

                          temp = qna;
                          console.log(temp);
                          setDataArrState(dataArr);

                        }}>수정</button>

                        <button onClick={() => {
                          // 이 버튼을 누르면 문답이 사라집니다.
                          var result = window.confirm('삭제 하시겠습니까?');

                          if (result) {
                            subject.QnA.splice(index, index + 1);

                            let aaaaaaaaa = [...dataArr]
                            localStorage.setItem('data', JSON.stringify(dataArr));
                            setDataArrState(aaaaaaaaa)
                          }

                        }}>x</button>
                      </div>



                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>




      {/* 모달창 */}

      <div className='modalSetting'>
        <div className='xbutton'>
          <Button
            variant='secondary'
            onClick={() => { document.querySelector('.modalSetting').classList.toggle('on-off') }}>x</Button>
        </div>

        <div>
          문답사이시간 :
          <input
            value={qnatimeA}
            onChange={(e) => { setQnatimeA(e.target.value) }}
            style={{ width: '30px' }}></input>
          초
        </div>

        <div>
          묶음사이시간 :
          <input
            value={qnatimeB}
            onChange={(e) => { setQnatimeB(e.target.value) }}
            style={{ width: '30px' }}></input>
          초
        </div>

      </div>

      <div className='MyModal'>

        <div className='xbutton'>
          <Button
            variant='secondary'
            onClick={() => { document.querySelector('.MyModal').classList.toggle('on-off') }}>x</Button>
        </div>

        <div>
          <input
            placeholder='질문'
            value={a}
            onChange={(e) => { setA(e.target.value) }}
          ></input>
        </div>

        <div>
          <input placeholder='답'
            value={b}
            onChange={(e) => { setB(e.target.value) }}
            style={{ height: '100px' }}
          ></input>
        </div>

        <div>

          <Button
            variant='primary'
            onClick={() => {

              if (name == '') {
                alert('그룹을 지정해 주세요.')
              }



              else {
                const obj = { 'question': a, 'answer': b }
                const input = { name: name, QnA: [obj] }
                const datafind = dataArr.find(obj => obj['name'] === name)

                if (button == '수정하기') {
                  temp.question = a;
                  temp.answer = b;
                  console.log(temp);
                  let aaaaaaa = [...dataArr];
                  localStorage.setItem('data', JSON.stringify(dataArr));
                  setDataArrState(aaaaaaa)

                  document.querySelector('.MyModal').classList.toggle('on-off');
                }



                else {


                  if (datafind == undefined) {

                    console.log('중복되는 name이 없음')
                    dataArr.push(input)
                    console.log(dataArr)
                    console.log(dataArrState)

                  }

                  else {

                    console.log('중복되는 name이 있음')

                    const newdata = dataArr.map(item => {
                      if (item.name === name) {
                        return { ...item, ...item.QnA.push(obj) }
                      }
                      return item;
                    })
                    // 위의 item은 키가 아닌 object 전체를 나타내는 거였음

                    console.log(newdata);
                    dataArr = newdata;
                    console.log(dataArrState);

                  }

                  setDataArrState(dataArr);
                  localStorage.setItem('data', JSON.stringify(dataArr));

                  setA('')
                  setB('')
                  document.querySelector('.MyModal').classList.toggle('on-off')
                }
              }

            }}
            style={{ marginTop: '30px' }}
          >{button}</Button>

        </div>
      </div>

    </div>
  );
}

export default App;

// 인터페이스 만들기
// 수정 삭제 버튼 ... 통합하기

// 서버 연동하기
// 프로젝트 컴포넌트, 파일 분리하기.

// 그룹추가+설정, 수학+x, 들어갔을때 여러가지 것들
// 총 3가지로 파일을 구성해보기
